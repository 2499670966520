/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

				var $viewportMeta = $('meta[name="viewport"]');
				$('input, select, textarea').bind('focus blur', function(event) {
				$viewportMeta.attr('content', 'width=device-width,initial-scale=1,maximum-scale=' + (event.type == 'blur' ? 10 : 1));
				});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


	var Resource = {
		load: function(e) {
			if(!$(".resource-conditional-select").length) return;

			if(sessionStorage.getItem('resourceConditional')) {
				var conditional = sessionStorage.getItem('resourceConditional');
				$(".resource-conditional-select").val(conditional);
			}

			Resource.changeClassFromSelect();
		},

		changeClassFromSelect: function() {

			var selected = $(".resource-conditional-select option").filter(":selected").val();

			try {
				sessionStorage.setItem('resourceConditional',selected);
			} catch(e) {
				console.log("Session storage isn't supported for this session.");
			}

			$(".resource-conditional").addClass("currently-hiding").delay(300).queue(function(next) {

				$(".resource-conditional-" + selected).removeClass("currently-hiding currently-hidden");
				$(".resource-conditional.currently-hiding").removeClass("currently-hiding").addClass("currently-hidden");

				next();
			});

		}
	}


  // Load Events
  $(document).ready(UTIL.loadEvents);

	var stickyfill = {
		loadEvents: function() {
			$('.sticky').Stickyfill();
		}
	};

	// Load Events
  $(document).ready(stickyfill.loadEvents);


	var fastclick = {
		loadEvents: function() {
			FastClick.attach(document.body);
		}
	}
	$(document).ready(stickyfill.loadEvents);

	$(document).ready(Resource.load);
	$(".resource-conditional-select").change(Resource.changeClassFromSelect);
	$(".modal").on("change", ".resource-conditional-select", Resource.changeClassFromSelect);
	$(document).ajaxComplete(Resource.load);



})(jQuery); // Fully reference jQuery after this point.
