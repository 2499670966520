(function($) {
	var inspirationPieceVideoPlayer;
  var inspirationPieceVideo = {
    loadEvents: function() {
			if($(".piece-box").length && $(".piece-box").attr("data-video")) {

				// Set up click events only for pertinent pages
				$(".piece-box").click(inspirationPieceVideo.clickEvents);

				// Set up YouTube asynchronously
				var tag = document.createElement('script');
				tag.src = "https://www.youtube.com/iframe_api";
				var firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			}

			// Separate space to load YouTube Events
			window.onYouTubeIframeAPIReady = function() {
				inspirationPieceVideoPlayer = new YT.Player('piece-player-iframe', {
					videoId: $(".piece-box").attr("data-video"),
					playerVars: {
						'enablejsapi': 1,
						'rel': 0,
						'color': 'white',
						'showinfo': 0
					},
					events: {
						//'onReady': inspirationPieceVideo.playerEvents,
						'onStateChange': inspirationPieceVideo.stateChangeEvents
					}
				});
			};
		},

			//$(".banner-upperright-menu a, .banner-overflow-hide-link").click(toggleMobileNavigation.clickEvents);
			//$(".cta.cta-with-popup").click(toggleMobileNavigation.ctaEvents);
			//$("body").click(stateReset.bodyResetEvents);
			//$(".cta-popup, .cta.cta-with-popup, .banner-upperright-menu a, .banner-overflow").click(stateReset.stopBodyResetEvents);
		clickEvents: function(e) {
			$(".piece-box").addClass("player-revealed");
			$(".piece-player").addClass("player-revealed");

			if(!navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
				inspirationPieceVideoPlayer.playVideo();
			}

			e.preventDefault();
		},
		stateChangeEvents: function(e) {
			if (e.data == YT.PlayerState.ENDED) {
				inspirationPieceVideoPlayer.stopVideo();

				$(".piece-box").removeClass("player-revealed");
				$(".piece-player").removeClass("player-revealed");
      }
		}
  };

	// Load events. Other events are conditionally handled.
  $(document).ready(inspirationPieceVideo.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
