(function($) {

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var toggleMobileNavigation = {
    loadEvents: function() {

		},
		mainClickEvents: function(e) {
			$(".banner-list").toggleClass("revealed");
			$('body').toggleClass('modal-revealed');
			$('html').toggleClass('modal-revealed');
			$(".banner-bar-menu").toggleClass("revealed");

			e.preventDefault();
		},
		subClickEvents: function(e) {
			$(".subbanner-overflow-link").toggleClass("revealed");
			//$('body').toggleClass('modal-revealed');
			//$('html').toggleClass('modal-revealed');
			$(".subbanner-navigation ul").toggleClass("revealed");

			e.preventDefault();
		},
		ctaEvents: function(e) {

			if($(".revealed-button").length && !($(this).is(".revealed-button"))) {
				$(".revealed-button").each(function() {
					$(this).removeClass("revealed-button");
					$(this).parent().find(".cta-popup").removeClass("revealed");
				});
			}

			$(this).toggleClass("revealed-button");
			$(this).parent().find(".cta-popup").toggleClass("revealed");

			e.preventDefault();
		}
  };

	var stateReset = {
		bodyResetEvents: function(e) {
			if($(".revealed-button").length || $("ul.revealed").length) {

				$(".revealed-button").removeClass("revealed-button");
				$(".cta-popup.revealed").removeClass("revealed");
				$("ul.revealed").removeClass("revealed");
				$(".subbanner-overflow-link").removeClass("revealed");

			}

			return true;
		},
		stopBodyResetEvents: function(e) {
			e.stopPropagation();
		}
	};

	var toggleSearchField = {
		clickEvents: function(e) {
			$(".banner-upperright-search").toggleClass("revealed");
		}
	};

	// Load Events
  //$(document).ready(toggleMobileNavigation.loadEvents);
	$(".banner-bar-menu").click(toggleMobileNavigation.mainClickEvents);
	$(".subbanner-overflow-link").click(toggleMobileNavigation.subClickEvents);
	$(".cta.cta-has-popup").click(toggleMobileNavigation.ctaEvents);
	$("body").click(stateReset.bodyResetEvents);
	$(".cta-popup, .cta.cta-has-popup, .banner-upperright-menu a, .subbanner-overflow-link").click(stateReset.stopBodyResetEvents);

})(jQuery); // Fully reference jQuery after this point.
